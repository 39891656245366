const data = {
  '404': {
    title: "Sorry, but the page you're looking for can't be found",
    message: `<p>It might have been removed, or it's address might have changed. If you
      typed the address of the page, please check your spelling carefully and
      try again. If that doesn't work, please visit our <a href="/">homepage</a></p>`,
    seoMetaData: {
      seoFields: [
        {
          keyName: 'robots',
          keyValue: 'nofollow, noindex',
        },
      ],
    },
  },
  default: {
    title: "Sorry, but we've experienced a technical error",
    message: `<p>We seem to have a technical problem that's preventing the page you
      requested from being shown. Please try again later, or visit our <a href="/">homepage</a></p>`,
    seoMetaData: {
      seoFields: [
        {
          keyName: 'robots',
          keyValue: 'nofollow, noindex',
        },
      ],
    },
  },
}

export default data
