import React, { FunctionComponent } from 'react'

import { Column, RichText, Row, Title } from '../../atoms'

import { Container } from './styles'
import { Props } from './types'

const PageError: FunctionComponent<Props> = (props: Props) => {
  const {
    data: { title, message },
  } = props

  return (
    <Container center={true}>
      <Row>
        <Column tablet={[6, 1]} desktop={[10, 1]}>
          <Title element={1} level={1}>
            {title}
          </Title>
        </Column>
      </Row>
      <Row>
        <Column tablet={[6, 1]} desktop={[6, 3]}>
          <RichText html={message} />
        </Column>
      </Row>
    </Container>
  )
}

export default PageError
