import { ThemeProvider } from 'styled-components'

import baseTheme from '../../../styles/theme'

const Theme = ({ children, name = 'default' }) => {
  const colorTheme = baseTheme.colorThemes[name] || baseTheme.colorThemes.blue

  const theme = {
    ...baseTheme,
    colorTheme,
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme
