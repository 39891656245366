import styled from 'styled-components'

import { Container as StyledContainer } from '../../atoms'

export const Container = styled(StyledContainer)`
  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-bottom: 0;
  }

  h1 {
    margin-bottom: 4rem;
  }

  a {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.blue};
    text-decoration: underline;
  }
`
