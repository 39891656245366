import React from 'react'

import PageError from '../../common/molecules/PageError'
import Layout from '../../common/templates/Layout'
import Theme from '../../containers/Theme'

import data from './__data__'
import { Wrapper } from './styles'
import { Props } from './types'

export const Error = (props: Props) => {
  const { statusCode } = props
  const { title, message, seoMetaData } =
    statusCode === 404 ? data['404'] : data.default
  const slug = `${statusCode}`

  return (
    <Theme>
      <Layout
        template="a"
        slug={slug}
        metaData={{
          seoMetaData: {
            ...seoMetaData,
            seoTitle: title,
          },
        }}
        noScrollToTop={true}
        isUsingTransparentHero={true}
      >
        <Wrapper>
          <PageError data={{ title, message }} />
        </Wrapper>
      </Layout>
    </Theme>
  )
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null

  return { statusCode }
}

export default Error
