import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 3rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 6em;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 12rem;
  }
`
